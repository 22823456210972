import { authPermissions } from '@/constants'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)


export const routes: Array<RouteConfig> = [
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('@/views/Login.vue'),
    //     meta: { noAuth: true }
    // },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        children: [
            {
                path: '/map',
                name: 'map',
                component: () => import('@/views/PropertyMapView.vue'),
                meta: { 
                    navBar:0,
                    text:'Map',
                }
            },
            {
                path: '/property',
                name: 'property',
                component: () => import('@/views/Property.vue'),
                meta: {
                    navBar:1,
                    text:'Property',
                }
            },
            {
                path: '/property/:propertyID',
                name: 'propertyDetail',
                component: () => import('@/views/PropertyDetail.vue')
            },
            {
                path: '/unit',
                name: 'unit',
                component: () => import('@/views/Unit.vue'),
                meta: {
                    navBar:2,
                    text:'Units',
                }
            },
            {
                path: '/tenant',
                name: 'tenant',
                component: () => import('@/views/Tenant.vue'),
                meta:
                {
                    navBar:3,
                    text:'Tenants',
                }
            },
            {
                path: '/tenant/:tenantID',
                name: 'tenantDetail',
                component: () => import('@/views/TenantDetail.vue'),
            },
            {
                path: '/vendor',
                name: 'vendor',
                component: () => import('@/views/Vendor.vue'),
                meta: {
                    navBar:4,
                    text:'Vendors',
                }
            },
            {
                path: '/jobs',
                name: 'jobs',
                component: () => import('@/views/Job.vue'),
                meta: {
                    navBar:5,
                    text:'Jobs',
                }
            },
            {
                path: '/accounting',
                name: 'accounting',
                component: () => import('@/views/Accounting.vue'),
                meta: {
                    navBar:6,
                    text: 'Accounting',
                }
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('@/views/User.vue'),
                meta: {
                    navBar:7,
                    text:'Users',
                    requiredPermissions: [authPermissions.canManageUsers]
                }
            },
            {
                path: '/userRoles',
                name: 'userRole',
                component: () => import('@/views/UserRole.vue'),
                meta: {
                    navBar:8,
                    text:'User Roles',
                    requiredPermissions: [authPermissions.canManageUsers]
                }
            },
            {
                path: '/clients',
                name: 'clients',
                component: () => import('@/views/Client.vue'),
                meta: {
                    navBar:8,
                    text:'Clients',
                    requiredPermissions: [authPermissions.canManageClients]
                }
            },
            {
                path: '/setting/:subPage?',
                name: 'setting',
                component: () => import('@/views/Setting.vue'),
                meta: {
                    navBar:9,
                    text:'Settings',
                    requiredPermissions: [authPermissions.canAccessAllRelatedSettings]
                }
            },
            {
                path: '/upload',
                name: 'upload',
                component: () => import('@/views/Upload.vue'),
                meta: {
                    navBar:10,
                    text:'Upload',
                    requiredPermissions: [authPermissions.canUseUpload]
                }
            },
            {
                path: '/release-notes',
                name: 'releaseNotes',
                component: () => import('@/views/ReleaseNotes.vue'),
                meta: {
                    text:'Release Notes',
                }
            },
            {
                path: '/',
                name: 'redirectHome',
                redirect: { name: 'jobs'}
            },
            {
                path: '*',
                name: 'notFound',
                component: () => import('@/views/NotFound.vue'),
                meta: {
                    text:'Not Found',
                    public: true
                }
            }
        ]
    },
    
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
